<template>
  <div class="nav">
    <div class="section-squares-fix d-none d-lg-flex flex-column justify-content-center align-items-end">

      <a title="Ir a preguntas frecuentes" alt="Ir a la sección de preguntas frecuentes de creativos espacios" class="square-hover-info d-flex justify-content-end" href="/preguntas">
        <div class="text-info-appear back-color-orange">
          <span class="font-size-18 font-koho-me ">Preguntas frecuentes</span>
        </div>
        <div class="icon-info-apper back-color-orange">
          <img class="icon-size-40" src="@/assets/img/icon-info-white.svg" title="preguntas frecuentes a creativos espacios" alt="icono de pregunta" description="icono de pregunta blanco">
        </div>
      </a>

     <a title="Ir a ubicación" alt="Ir a la sección de ubicación de creativos espacios" class="square-hover-info d-flex justify-content-end" href="/localizacion">
        <div class="text-info-appear back-color-green">
          <span class="font-size-18 font-koho-me ">Encuéntranos</span>
        </div>
        <div class="icon-info-apper back-color-green">
          <img class="icon-size-40" src="@/assets/img/icon-place-white.svg" title="encuentra a creativos espacios" alt="icono de lugar" description="icono de lugar blanco">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>