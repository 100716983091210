<template>
  <div id="videos-adecuaciones" class="videoAdecuaciones">
    <div class="container-fluid">
      <div class="row">
        <div class="col-10 col-md-6 col-lg-4 back-color-bluelight">
          <h2 class="font-nuni-bo font-color-white font-size-42 text-left mb-0 py-3 px-0 px-sm-5">Videos</h2>
        </div>
      </div>
    </div>
    <div class="container-fluid overflow-hidden">
      <div class="row d-flex justify-content-center back-color-bluelight position-relative">    
         <div class="squares-border-white position-absolute h-75 w-50 ">
            <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
            <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
            <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
          </div>    
        <div class="w-100 d-block"></div>
         <div class="col-12 col-md-12 col-lg-10 col-xl-8 py-5">

          <b-tabs pills card vertical end nav-wrapper-class="w-50 w-md-100 video-height-400"
            nav-class=" video-height-400 overflow-auto d-flex flex-column p-0 flex-nowrap">
            <b-tab active lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/videos/venta-de-contenedores-maritimos.webp" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Venta de contenedores marítimos en Méxic</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/oaFMasSUM80"></iframe>
            </b-tab>
            <b-tab  lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/videos/renta-de-contenedores-maritimos.webp" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Renta de contenedores marítimos en Méxic</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/igD7uzwF7cY"></iframe>
            </b-tab>
            <b-tab  lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/videos/modificacion-de-contenedores-maritimos.webp" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Modificacion de contenedores marítimos en México</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/Yc9Z4V1piQ8"></iframe>
            </b-tab>
            <b-tab  lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/videos/conoce-a-creativos-espacios.webp" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Conoce a Creativos Espacios</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/uMXB54itJeM"></iframe>
            </b-tab>
            <b-tab  lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/videos/servicios-de-contenedores-maritimos.webp" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Servicios de Creativos Espacios</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/prXleMCzWCI"></iframe>
            </b-tab>
            <b-tab  lazy>
              <template #title>
                <div class="ancor-video-hover d-flex flex-column flex-sm-row align-items-center ">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/locales-comerciales-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Locales comerciales en
                      contenedores
                      marítimos Túlum</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/wmfOLg8p9Ms"></iframe>
            </b-tab>

            <b-tab lazy>
              <template #title>
                <div class="ancor-video-hover flex-column flex-sm-row d-flex align-items-center">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/adaptacion-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left">Adaptación en contenedores
                      marítimos</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/hwlXht0qlHg"></iframe>
            </b-tab>

            <b-tab lazy>
              <template #title>
                <div class="ancor-video-hover flex-column flex-sm-row d-flex align-items-center">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/modificacion-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left">Transformación de contenedores
                      marítimos</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/T1LcbeRz6Ps"></iframe>
            </b-tab>

            <b-tab lazy>
              <template #title>
                <div class="ancor-video-hover flex-column flex-sm-row d-flex align-items-center">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/venta-contenedores-maritimos-usados.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Venta de contenedores marítimos
                    </h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/8Ia0XnKUiUo"></iframe>
            </b-tab>

            <b-tab lazy>
              <template #title>
                <div class="ancor-video-hover flex-column flex-sm-row d-flex align-items-center">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/renta-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Renta de contenedores marítimos
                    </h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/ncUwE9ugrz4"></iframe>
            </b-tab>

            <b-tab lazy>
              <template #title>
                <div class="ancor-video-hover flex-column flex-sm-row d-flex align-items-center">
                  <div class="p-3">
                    <img src="@/assets/img/tipos/venta-contenedores.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                  </div>
                  <div class="p-3">
                    <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Modificación de contenedores
                      marítimos</h3>
                  </div>
                </div>
              </template>
              <iframe class="w-100 video-height-400" src="https://www.youtube.com/embed/kNMNgMMHR24"></iframe>
            </b-tab>

          </b-tabs>

        </div> <!---
        <div class="col-8">            

         
 <div class="d-flex justify-content-center align-items-start py-5">
            <div class="embed-responsive embed-responsive-21by9 video-height-400">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wmfOLg8p9Ms"></iframe>
            </div>
            <div class="overflow-auto d-flex flex-column video-height-400">
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center active">
                <div class="p-3">
                  <img src="@/assets/img/tipos/locales-comerciales-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left ">Locales comerciales en contenedores
                    marítimos Túlum</h3>
                </div>
              </a>
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center">
                <div class="p-3">
                  <img src="@/assets/img/tipos/adaptacion-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left">Adaptación en contenedores marítimos</h3>
                </div>
              </a>
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center">
                <div class="p-3">
                  <img src="@/assets/img/tipos/modificacion-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class=" font-size-14 font-color-brown font-nuni-bo text-left">Transformación de contenedores marítimos</h3>
                </div>
              </a>
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center">
                <div class="p-3">
                  <img src="@/assets/img/tipos/venta-contenedores-maritimos-usados.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Venta de contenedores marítimos</h3>
                </div>
              </a>
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center">
                <div class="p-3">
                  <img src="@/assets/img/tipos/renta-contenedores-maritimos.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Renta de contenedores marítimos</h3>
                </div>
              </a>
              <a title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios" class="ancor-video-hover d-flex align-items-center">
                <div class="p-3">
                  <img src="@/assets/img/tipos/venta-contenedores.jpg" title="video de la galeria" alt="video de la galeria de creativos espacios" description="video de la galeria de contenedores creativos espacios">
                </div>
                <div class="p-3">
                  <h3 class="font-size-14 font-color-brown font-nuni-bo text-left">Modificación de contenedores marítimos</h3>
                </div>
              </a>             
            </div>
          </div>

          
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoAdecuaciones',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>