<template>
  <div class="header font-koho-me">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-12 col-lg-2 pt-3">
          <a title="logo creativos espacios" alt="logo imagina creativos espacios" href="/"> <img src="@/assets/img/header/logo-creativos-espacios-contenedores-maritimos.svg"
              title="logo creativos espacios" alt="logo imagina creativos espacios"
              description="logo creativos espacios venta renta y modificación de contenedores marítimos"></a>
        </div>
        <div class="col-12 col-lg-9 d-flex justify-content-between flex-column ">
          <div class="row d-flex justify-content-end align-items-start ">
            <div
              class="col-12 col-md-9 col-lg-6 col-xl-6 d-flex flex-column align-items-end align-self-center pr-3 mt-3 mt-lg-0">
              <div><span>Tel. </span><a title="llamar a creativos espacios" alt="teléfono de creativos espacios" class="font-color-blueblack line-height-1-4"
                  href="tel:5526088867">5526088867</a><span> / </span><a title="llamar a creativos espacios" alt="teléfono de creativos espacios" class="font-color-blueblack line-height-1-4"
                  href="tel:5554269941">5554269941</a></div>
              <div><span>Mail. </span><a title="mandar correo a creativos espacios" alt="correo electrónico de creativos espacios" class="font-color-blueblack line-height-1-4"
                  href="mailto:ventas@creativosespacios.mx">ventas@creativosespacios.mx</a></div>
            </div>
            <div
              class="col-12 col-md-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center p-2 back-color-blue mt-3 mt-lg-0">
              <a title="configuración del sitio" alt="abrir la configuración del sitio de creativos espacios" class="head-icon-style back-icon-blue p-2 mx-2 d-none" href="">
                <img src="@/assets/img/header/icono-configuracion-blanco.svg" title="configuración"
                  alt="configuración del sitio" description="configuración del sitio de creativos espacios">
              </a>
              <a title="Ir a encontrar" alt="Ir a encontrar a creativos espacios" class="head-icon-style back-icon-blue p-2 mx-2" href="/localizacion">
                <img src="@/assets/img/header/icono-lugar-blanco.svg" title="oficinas"
                  alt="oficinas de creativos espacios" description="lugar de las oficinas de creativos espacios">
              </a>
              <button class="head-icon-style back-icon-blue position-relative p-2 mx-2" id="show-btn"
                @click="$bvModal.show('bv-modal-example')">
                <img src="@/assets/img/header/icono-notificacion-blanco.svg" title="notificaciones"
                  alt="notificaciones del sitio" description="notificaciones del sitio de creativos espacios">
                  <b-badge class="position-absolute" variant="light">1</b-badge>
              </button>
            </div>
          </div>
          <div class="row mt-5 mt-lg-0">
            <div class="col-12">
              <div>
                <nav class="navbar navbar-expand-md back-color-blue font-color-white navbar-custom-styles">
                  <ul class="navbar-nav justify-content-around w-100">
                    <li class="nav-item " v-bind:class="{ 'active': $route.path == '/' }">
                      <a title="Página de inicio" alt="Página de inicio de creativos espacios" class="nav-link" href="/">Inicio <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item" v-bind:class="{ 'active': $route.path == '/conocenos' }">
                      <a title="Página de conócenos" alt="Página de conócenos de creativos espacios" class="nav-link" href="/conocenos">Conócenos</a>
                    </li>
                    <li class="nav-item" v-bind:class="{ 'active': $route.path == '/tipos' }">
                      <a title="Página de tipos de contenedores" alt="Página de tipos de contenedores de creativos espacios" class="nav-link" href="/tipos">Tipos de contenedor</a>
                    </li>
                    <li class="nav-item" v-bind:class="{ 'active': $route.path == '/adecuaciones' }">
                      <a title="Página de adecuaciones" alt="Página de adecuaciones de creativos espacios" class="nav-link" href="/adecuaciones">Adecuaciones</a>
                    </li>
                    <li class="nav-item" v-bind:class="{ 'active': $route.path == '/cotizacion' }">
                      <a title="Página de cotizaciones" alt="Página de cotizaciones de creativos espacios" class="nav-link" href="/cotizacion">Cotización</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="bv-modal-example" centered hide-footer hide-header>
      <template #default="{ hide }" >
        <h4 class="font-nuni-bo font-size-20 font-color-blueblack mb-4 text-left">Juntos evitemos las estafas</h4>
      <p class="d-block w-100 font-koho-re font-size-14 font-color-brown text-left mb-4"> 
Agenda cita para seleccionar tu contenedor, solicita los documentos en regla, deposita a la cuenta del nombre de la empresa.<br>
<a title="Ir a más información sobre estafas" alt="Información sobre las estafas con contenedores marítimos" href="https://www.elnorte.com/denuncian-estafas-en-ventas-de-acero/gr/ar1704797">Más información</a></p>
      <button class="button-out-blue" @click="hide()">Ok</button>       
    </template>    
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
