  <template>
    <div class="beneficios mt-5">
      <div class="container-fluid font-color-white overflow-hidden">
        <div class="row">
          <div class="col-10 col-md-6 col-lg-4 back-color-bluebold">
            <h2 class="font-nuni-bo font-size-42 text-left py-3 px-0 px-sm-5">Beneficios</h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center back-color-bluebold back-tram-parallax ">
          <div class="col-12 col-lg-4 col-xl-4 d-flex align-items-end justify-content-center position-relative">
            <div class="position-relative">
 <div class="firework-efect-animation">
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
              <div class="firework"><div class="spark"></div></div>
            </div>
            <img class="img-fluid" src="@/assets/img/inicio/herreria-contenedores-maritimos.png" title="herrero de contenedores marítimos" alt="imagen de herrero de contenedores marítimos" description="imagen de herrero de contenedores marítimos azul">
            </div>
           
          </div>
          <div class="col-12 col-lg-8 col-xl-8 py-5 px-2 p-md-5">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-10.png" title="10 años de experiencia" alt="icono número diez" description="icono número diez azul">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18 mt-3 mt-lg-0">Profesionalismo</h3>
                    <p class="font-koho-me font-size-14">10 años de experiencia respaldan tu compra.</p>
                  </div>
                </div>
              </div>
              <div class="col-6 ">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-calidad.png" title="Cortesía" alt="icono calidad" description="icono calidad azul">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Cortesía</h3>
                    <p class="font-koho-me font-size-14">Atención y asesoramiento cuando lo requieras, acompañarte en todo.</p>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-compromiso.png" title="Compromiso social" alt="icono de reciclaje" description="icono de reciclaje verde">
                  </div>
                 <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Compromiso social</h3>
                    <p class="font-koho-me font-size-14">Nuestros contenedores marítimos respetan los valores ecológicos, reutilizando.</p>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-flexibilidad.png" title="Flexibilidad y adaptación" alt="icono mejores" description="icono mejores dorado">
                  </div>
                   <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Flexibilidad / adaptación</h3>
                    <p class="font-koho-me font-size-14">Desarrollo de diseños personalizados y únicos para satisfacer tus necesidades.</p>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-iso.png" title="Responsabilidad" alt="icono ISO" description="icono ISO azul">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Responsabilidad</h3>
                    <p class="font-koho-me font-size-14">Entregamos la documentación legal correspondiente.</p>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="row">
                   <div class="col-12 col-lg-4">
                    <img class="img-fluid" src="@/assets/img/inicio/creativos-espacios-mercado.png" title="Fácil adquisión" alt="icono de mercado pago" description="icono de mercado pago azul">
                  </div>
                  <div class="col-12 col-lg-8 text-left ">
                    <h3 class="font-nuni-bo font-size-18  mt-3 mt-lg-0">Fácil adquisión</h3>
                    <p class="font-koho-me font-size-14">Trabajamos con mercado pago y proximamente meses sin intereses.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>


<script>
export default {
  name: 'Beneficios',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
