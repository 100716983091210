  <template>
    <div  class="servicios">
      <div id="servicios-inicio" class="servicios-inicio mt-5 pb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-10 col-md-6 col-lg-4 back-color-metalblack">
              <h2 class="font-nuni-bo font-color-white font-size-42 text-left py-3 px-0 px-sm-5">Servicios</h2>
            </div>
          </div>
          <div class="row position-relative">
            <div class="d-flex w-100 h-100 position-absolute flex-row">
              <div class="d-none d-md-flex flex-column w-100 h-100">
                <div class=" w-100 h-50 back-color-metalblack">
                </div>
                <div class="w-100 h-50 back-color-blue">
                </div>
              </div>
              <div class=" w-100 h-100 d-block d-md-none back-color-metalblack">
                </div>
                <div class="w-100 h-100 d-block d-md-none back-color-blue">
                </div>
              <div class="w-100 h-100 back-color-yellowlight">
              </div>
            </div>
            <div class="col-12">
              <div class="row font-color-brown mx-3 my-4 m-md-5 m-lg-5">
                <div class="col-12 col-lg-4 back-color-yellowlight p-3 p-md-5 p-lg-4 p-xl-5">
                  <div class="border-color-brown text-left h-100 p-3 p-md-5 p-lg-3 p-xl-3">
                    <h2 class="font-size-24 font-nuni-bo text-center">Venta y renta</h2>
                    <p class="font-size-18 font-koho-me text-left">
                      Compra el contenedor marítimo que necesitas o rentalo por el tiempo que lo requieras.
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 back-color-white p-2 d-flex align-items-center justify-content-center">
                  <img class="img-fluid" src="@/assets/img/inicio/contenedor-maritimo-plano-negocio.png" title="plano de contenedor marítimo transformado en negocio" alt="imagen de plano de contenedor marítimo transformado en negocio" description="imagen de plano de contenedor marítimo transformado en negocio blanco y negro">
                </div>
                <div class="col-12 col-lg-4 back-color-blue p-5 py-md-4 mt-md-5 mt-lg-0 px-md-5 p-lg-4 p-xl-5 d-flex align-items-center ">
                  <div class="squares-border-white position-absolute h-75 w-75 h-lg-85 w-lg-85">
                    <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
                    <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
                    <div class="square-animation-style border-color-white position-absolute h-100 w-100"></div>
                  </div>
                  <div class="p-0 px-md-5 p-lg-3 p-xl-3">
                    <h2 class="font-size-24 font-nuni-bo text-center">Modificación</h2>
                    <p class="font-size-18 font-koho-me text-left">
                      Adaptamos los contenedores marítimos acorde a tus preferencias y necesidades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="venta-de-contenedores" class="servicio-venta mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-yellowlight position-relative">
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/venta-contenedores-maritimos.png" title="venta de contenedor marítimo" alt="imagen de contenedor marítimo nuevo" description="imagen de contenedor marítimo nuevo azul">
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center pr-lg-5">
                    <h2 class="font-size-24 font-color-brown font-nuni-bo text-left pt-3 pt-lg-0 px-3 pl-lg-0">Venta de contenedores<br class="d-none d-lg-block"> marítimos
                    </h2>
                    <p class="font-size-14 font-color-brown font-koho-me text-left px-3 pl-lg-0 mt-3">Tenemos contenedores marítimos
                      de
                      diversos tipos y tamaños como de 10 pies, 20 pies y 40 pies de
                      largo.</p>
                    <div class="d-flex justify-content-around w-100 pb-5 pb-lg-0">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-blue font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        comprar</a>
                      <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-blue font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/tipos">Saber más</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="renta-de-contenedores" class="servicio-renta mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-metalblack position-relative">
                <div class="square-decoration-white back-color-white position-absolute d-none d-lg-block"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0 d-flex flex-column align-items-start justify-content-center pl-lg-5">


                    <h2 class="font-size-24 font-color-white font-nuni-bo text-left  pl-3 pl-lg-0">Renta de contenedores<br class="d-none d-lg-block"> marítimos
                    </h2>
                    <p class="font-size-14 font-color-white font-koho-me text-left mt-3 pl-3 pl-lg-0">Contamos con la renta de contenedores marítimos listos para ser ocupados, contáctanos para conocer la disponiblidad y documentación necesaria para obtener el tuyo.</p>
                    <div class="d-flex justify-content-around w-100 ">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-yellow font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        rentar</a>
                        <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-yellow font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/tipos">Saber más</a>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0 pb-5 pb-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/renta-contenedores-maritimos.png" title="renta de contenedor marítimo" alt="imagen de contenedor marítimo usado" description="imagen de contenedor marítimo usado verde">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modificacion-de-contenedores" class="servicio-modificacion mt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="back-color-blue position-relative">
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="square-decoration-white back-color-white position-absolute"></div>
                <div class="row">
                  <div class="col-12 col-lg-6 pt-5 pt-lg-0">
                    <img class="img-fluid image-servicios-zoom"
                      src="@/assets/img/inicio/modificacion-contenedores-maritimos.png" title="transformación de contenedores marítimos" alt="imagen de transformación de contenedores marítimos" description="imagen de transformación de contenedores marítimos café">
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center pr-lg-5">
                    <h2 class="font-size-24 font-color-brown font-nuni-bo text-left pt-3 pt-lg-0 px-3 pl-lg-0">Modificación de contenedores<br class="d-none d-lg-block"> maritimos
                    </h2>
                    <p class="font-size-14 font-color-brown font-koho-me text-left px-3 pl-lg-0 mt-3">Transforma tu contenedor marítimo te ayudamos si estas en cero o si eres todo un experto. No olvides que requieres las medidas de tu terreno, servicios básicos (luz, agua, etc) y muchas ganas de alcanzar tus sueños.</p>
                    <div class="d-flex justify-content-around w-100 pb-5 pb-lg-0">
                      <a title="Ir a cotizar" alt="Ir a la sección de cotización" class="button-back-brown font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/cotizacion">Quiero
                        una cotización</a>
                        <a title="Ir a tipos de contenedores" alt="Ir a la sección de tipos" class="button-back-brown font-koho-me font-size-14 py-3 mt-3 d-block button-size-125"
                        href="/adecuaciones">Saber más</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'Servicios',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
