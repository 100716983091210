<template>
  <div class="banner">
    <div class="container-fluid section-banner-styles back-color-blue mt-5 position-relative">
      <div class="triangle-top-left "><img class="img-triangle-tl" src="@/assets/img/inicio/triangle-top-left.png" title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-top-right "><img class="img-triangle-tr" src="@/assets/img/inicio/triangle-top-right.png"  title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-bottom-left"><img class="img-triangle-bl" src="@/assets/img/inicio/triangle-bottom-left.png"  title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>
      <div class="triangle-bottom-right "><img class="img-triangle-br" src="@/assets/img/inicio/triangle-bottom-right.png"  title="figura triangular" alt="imagen de figura triangular" description="imagen de figura triangular de color azul blanco"></div>     
      <div class="row py-5">
        <div class=" col-12 col-lg-5 col-xl-6 overflow-sm-hidden">
          <a title="Mostrar que hay dentro del contenedor" alt="Activación de la animación del contenedor" class="switch-img-transform d-flex justify-content-center" href="#banner">
          <img class="img-fluid animation-firts-container first-img" src="@/assets/img/inicio/contenedor-maritimo-amarillo.png" title="contenedor nuevo" alt="imagen de contenedor nuevo" description="imagen de contenedor nuevo amarillo">
          <img class="img-fluid animation-firts-container hover-img" src="@/assets/img/inicio/contenedor-maritimo-transformado-amarillo.png" title="contenedor oficina" alt="imagen de contenedor oficina" description="imagen de contenedor oficina amarillo">
          <img class="img-fluid cursor-indicator-animation" src="@/assets/img/inicio/icon-pointer-color.svg" title="coloca el puntero aquí" alt="indicador de puntero" description="imagen de indicador de puntero">
          </a>
        </div>
        <div class="col-12 col-lg-7 col-xl-6">
          <div class="title-principal-styles text-left d-flex flex-column justify-content-center align-items-center align-items-lg-start h-100">
            <div class="position-relative">
              <h1 class="font-nuni-eb font-size-48 font-color-white line-heigt-1 position-relative  z-index-2 mb-0">Compra, renta o <br class="">personaliza
                <br>contenedores marítimos</h1>
                <div class="container-rectangles-blue position-absolute z-index-1">
                  <div class="back-rectangle-blue position-relative"></div>
                <div class="back-rectangle-blue position-relative"></div>
                <div class="back-rectangle-blue position-relative"></div>
                </div>
                
            </div>
            <div class="mt-3 align-self-center">
              <a title="Ir a localización" alt="Ir a localización de cretivos espacios" href="/localizacion" class="button-back-yellow font-koho-me font-size-14 py-3 mt-3 d-block button-size-320">Quiero visitarlos y conocerlos</a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    msg: String
  },
    data () {
    return {          
       window: {         
            width: 0,
            height: 0
        }
    }
  },
 created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
           console.log(this.window.width);
        }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
