<template>
  <div class="home">
    <Header />
    <Nav/>
    <Banner />
    <VideosAdecuaciones />
    <Servicios/>
    <Beneficios/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Banner from '@/components/Banner.vue'
import VideosAdecuaciones from '../components/VideosAdecuaciones.vue'
import Servicios from '@/components/Servicios.vue'
import Beneficios from '@/components/Beneficios.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'Home',
  components: {
    Header,
    Nav,
    Banner,
    VideosAdecuaciones,
    Servicios,
    Beneficios,
    Footer,
    
}
}
</script>
