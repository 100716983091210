<template>
  <div class="footer back-color-metalblack pt-5 pb-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-white text-left">Búscanos</h3>
          <div class="d-flex justify-content-start mt-3">
           <a title="Mandar mensaje por whatsapp" alt="Mandar mensaje por whatsapp a creativos espacios" href="https://wa.me/522291846751?text=hola Creativos Espacios" target="_blank">
            <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-whatsapp.svg"
              title="whatsapp de creativos espacios" alt="icono de whatsapp" description="icono de whatsapp blanco"></a>
           <a title="Mandar mensaje por telegram" alt="Mandar mensaje por telegram a creativos espacios" href="https://telegram.me/CreativosEspacios" target="_blank"><img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-telegram.svg" title="telegram de creativos espacios" alt="icono de telegram" description="icono de telegram blanco"></a>
          <a title="Ir a la ubicación en google maps" alt="Ir a la ubicación en google maps de creativos espacios" href="https://goo.gl/maps/4tHR2gzpmCGGqNhA7" target="_blank"><img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/icon-place-white.svg" title="ubicación de creativos espacios" alt="icono de ubicación" description="icono de ubicación blanco"></a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-white text-left">Conócenos</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2"><a title="Ir a conócenos" alt="Ir a la sección conócenos de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/conocenos">Conócenos</a></li>
            <li class="my-2"><a title="Ir a tipos de contenedores" alt="Ir a la sección tipos de contenedores de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/tipos">Tipos de contenedores</a></li>
            <li class="my-2"><a title="Ir a adecuaciones de contenedores" alt="Ir a la sección adecuaciones de contenedores de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/adecuaciones">Adecuaciones</a></li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 font-color-white  text-left mt-5 mt-lg-0">
          <h3 class="font-nuni-bo font-size-18 font-color-white text-left">Atención a clientes</h3>
          <p class="font-size-14 font-koho-me text-left">
            CDMX<br>
            Av. del Árbol 104,<br>
            Col. Lomas de San Lorenzo,<br>
            C.P. 09780, Alcaldía Iztapalapa.
          </p>
          <p class="font-size-14 font-koho-me text-left"> Teléfono:
           <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5526088867" class="ancor-style-generic font-color-white p-1">55 26 08 88 67</a><br>
           <a title="Llamar por teléfono" alt="Llamar por teléfono a creativos espacios" href="tel:5554269941" class="ancor-style-generic font-color-white p-1 my-2 d-inline-block">&nbsp;55 54 26 99 41</a>
          </p>
            <p class="font-size-14 font-koho-me text-left"> Email:
           <a title="Mandar un correo electrónico a ventas" alt="Mandar un correo electrónico a ventas de creativos espacios" href="mailto:ventas@creativosespacios.mx" class="ancor-style-generic font-color-white p-1">ventas@creativosespacios.mx</a><br>
           <a title="Mandar un correo electrónico a facturación" alt="Mandar un correo electrónico a facturación de creativos espacios" href="mailto:facturacion@creativosespacios.mx" class="ancor-style-generic font-color-white p-1 my-2 d-inline-block">&nbsp;facturacion@creativosespacios.mx</a>
          </p>
          <p class="font-size-14 font-koho-me text-left">
            Lunes a viernes de<br>
            8:00am a 6:00pm
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 mt-5 mt-lg-0">
 <h3 class="font-nuni-bo font-size-18 font-color-white text-left">Podemos ayudarte</h3>
          <ul class="list-unstyled font-koho-me font-size-14 text-left">
            <li class="my-2">
              <a title="Ir a localización" alt="Ir a la sección localización de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/localizacion">Conoce nuestras ubicaciones</a></li>
            <li class="my-2">
              <a title="Ir a sitemap" alt="Ir a la sección sitemap de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/sitemap">Mapa de sitio</a></li>
            <li class="my-2">
              <a title="Ir a preguntas frecuentes" alt="Ir a la sección preguntas frecuentes de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/preguntas">Preguntas frecuentes</a></li>
            <li class="my-2">
              <a title="Ir a cotización" alt="Ir a la sección cotización de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/cotizacion">Cotización</a></li>
          </ul>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h3 class="font-nuni-bo font-size-18 font-color-white text-left">Síguenos</h3>
          <div class="d-flex justify-content-start mt-3">
           <a title="Ir al instagram de creativos espacios" alt="Ir al perfil de instagram de creativos espacios" href="https://www.instagram.com/creativosespaciosmx" target="_blank"> 
            <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-instagram.svg"
              title="instagram de creativos espacios" alt="icono de instagram" description="icono de instagram blanco"></a>
            <a title="Ir al facebook de creativos espacios" alt="Ir al perfil de facebook de creativos espacios" href="https://www.facebook.com/creativosespaciosmx" target="_blank"> 
              <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-facebook.svg"
              title="facebook de creativos espacios" alt="icono de facebook" description="icono de facebook blanco"></a>
             <a title="Ir al youtube de creativos espacios" alt="Ir al perfil de youtube de creativos espacios" href="https://www.youtube.com/channel/UCEAQ-yq9sq0C-KCX8-aRyZA" target="_blank">
              <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-youtube.svg"
              title="youtube de creativos espacios" alt="icono de youtube" description="icono de youtube blanco"></a>
             <a title="Ir al twitter de creativos espacios" alt="Ir al perfil de twitter de creativos espacios" href="https://twitter.com/creaespaciosmx" target="_blank">
              <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-twitter.svg"
              title="twitter de creativos espacios" alt="icono de twitter" description="icono de twitter blanco"></a>
             <a title="Ir al tiktok de creativos espacios" alt="Ir al perfil de tiktok de creativos espacios" href="https://www.tiktok.com/@creativosespaciosmx" target="_blank">
              <img class="img-fluid icon-footer-size mr-4" src="@/assets/img/footer/creativos-espacios-tiktok.svg" 
              title="tiktok de creativos espacios" alt="icono de tiktok" description="icono de tiktok blanco"></a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
<ul class="list-inline font-koho-me font-size-12 font-color-white text-center">
  <li class="list-inline-item p-1">Todo los derechos reservados 2021-22 |</li>
  <li class="list-inline-item"><a title="Ir a leer los términos y condiciones" alt="Ir a leer los términos y condiciones de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/terminos">Términos y condiciones</a> |</li>
  <li class="list-inline-item"><a title="Ir a leer el aviso de identidad" alt="Ir a leer el aviso de identidad de creativos espacios" class="ancor-style-generic font-color-white p-1" href="/aviso">Aviso de identidad</a></li>
</ul>
        </div>
         
      </div>
      <div class="row mt-5">
         <div class="col-12 text-center font-color-white">
           <p class="font-size-14 font-koho-me">Desarrolla por <a title="Visitar a Apterynet desarrolladores del sitio" alt="Visitar a Apterynet desarrolladores del sitio de creativos espacios" href="https://apterynet.net" target="_blank" class="ancor-style-generic font-color-white p-1"> Apterynet</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
